import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Descriptions, message } from 'antd';
import { fetchDomainDetail } from '../../api/apiDomain'; // 假设你有获取域名详情的 API
import type { Domain } from '../../models/test/Domain';

const DomainDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 从 URL 中获取域名 ID
  const navigate = useNavigate(); // 使用 useNavigate
  const [domain, setDomain] = useState<Domain | null>(null); // 存储域名详情
  const [loading, setLoading] = useState(false); // 加载状态

  // 获取域名详情
  useEffect(() => {
    const fetchDomain = async () => {
      setLoading(true);
      try {
        const response = await fetchDomainDetail(Number(id)); // 调用封装的 API 获取域名详情
        setDomain(response.data);
      } catch (error) {
        console.error('Error fetching domain details:', error);
        message.error('无法获取域名详情');
      } finally {
        setLoading(false);
      }
    };

    fetchDomain();
  }, [id]);

  // 返回域名列表
  const handleBack = () => {
    navigate('/test/domain-list');
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!domain) {
    return <p>未找到域名详情</p>;
  }

  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">{domain.domainName} - 详情</h2>
      <Descriptions bordered>
        <Descriptions.Item label="域名">{domain.domainName}</Descriptions.Item>
        <Descriptions.Item label="注册商">{domain.registrar}</Descriptions.Item>
        <Descriptions.Item label="注册日期">{domain.registrationDate}</Descriptions.Item>
        <Descriptions.Item label="到期日期">{domain.expirationDate}</Descriptions.Item>
        <Descriptions.Item label="持有者">{domain.ownerName}</Descriptions.Item>
        <Descriptions.Item label="持有者邮箱">{domain.ownerEmail}</Descriptions.Item>
        <Descriptions.Item label="状态">{domain.status}</Descriptions.Item>
        <Descriptions.Item label="域名服务器">{domain.nameservers}</Descriptions.Item>
        <Descriptions.Item label="品牌图片">
          <img src={domain.imageUrl} alt={domain.domainName} style={{ width: '100px' }} />
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" onClick={handleBack} className="mt-4">
        返回列表
      </Button>
    </div>
  );
};

export default DomainDetailPage;