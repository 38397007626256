import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Descriptions, message, Spin } from 'antd';
import { fetchBookDetail } from '../../api/apiBook'; // 假设你有获取书籍详情的 API
import type { Book } from '../../models/test/Book';

const BookDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 从 URL 中获取书籍 ID
  const navigate = useNavigate(); // 使用 useNavigate
  const [book, setBook] = useState<Book | null>(null); // 存储书籍详情
  const [loading, setLoading] = useState(false); // 加载状态

  // 获取书籍详情
  useEffect(() => {
    const fetchBook = async () => {
      setLoading(true);
      try {
        const response = await fetchBookDetail(Number(id)); // 调用封装的 API 获取书籍详情
        if (response.data) {
          setBook(response.data);
        } else {
          message.error('未找到相关的书籍信息');
        }
      } catch (error) {
        console.error('Error fetching book details:', error);
        message.error('无法获取书籍详情');
      } finally {
        setLoading(false);
      }
    };

    fetchBook();
  }, [id]);

  // 返回书籍列表
  const handleBack = () => {
    navigate('/test/book-list');
  };

  // 如果正在加载，显示 Spin 组件
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin tip="加载中..." size="large" />
      </div>
    );
  }

  // 如果未找到书籍，显示提示
  if (!book) {
    return <p>未找到书籍详情</p>;
  }

  // 正常渲染书籍详情
  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">{book.title} - 详情</h2>
      <Descriptions bordered>
        <Descriptions.Item label="书籍标题">{book.title}</Descriptions.Item>
        <Descriptions.Item label="作者">{book.author}</Descriptions.Item>
        <Descriptions.Item label="分类">{book.category}</Descriptions.Item>
        <Descriptions.Item label="价格">{`$${book.price.toFixed(2)}`}</Descriptions.Item>
        <Descriptions.Item label="ISBN">{book.isbn}</Descriptions.Item>
        <Descriptions.Item label="标签">{book.tags}</Descriptions.Item>
        <Descriptions.Item label="简介">{book.description}</Descriptions.Item>
        <Descriptions.Item label="封面">
          <img src={book.imageUrl} alt={book.title} style={{ width: '100px' }} />
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" onClick={handleBack} className="mt-4">
        返回列表
      </Button>
    </div>
  );
};

export default BookDetailPage;