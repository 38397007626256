import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import AdminLoginLayout from './layouts/AdminLoginLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Templates from './pages/Templates';
import BookListPage from './pages/test/BookListPage';
import BookDetailPage from './pages/test/BookDetailPage';
import DomainListPage from './pages/test/DomainListPage';
import DomainDetailPage from './pages/test/DomainDetailPage';
import TestIndexPage from './pages/test/TestIndexPage';
import PickerTestPage from './pages/test/PickerTestPage';
import ImageTestPage from './pages/test/ImageTestPage';
import DrawerTestPage from './pages/test/DrawerTestPage';
import CashierTestPage from './pages/test/CashierTestPage';
import AdminLoginPage from './pages/login/AdminLoginPage';
import CashierTestPage2 from './pages/test/CashierTestPage2';

const App: React.FC = () => {
  return (
    <Routes>
      {/* 使用 MainLayout 包装的路由 */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/test/book-list" element={<BookListPage />} />
        <Route path="/test/book-detail/:id" element={<BookDetailPage />} />
        <Route path="/test/domain-list" element={<DomainListPage />} />
        <Route path="/test/domain-detail/:id" element={<DomainDetailPage />} />
        <Route path="/test/picker-test" element={<PickerTestPage />} />
        <Route path="/test/image-test" element={<ImageTestPage />} />
        <Route path="/test/drawer-test" element={<DrawerTestPage />} />
        <Route path="/test/cashier-test" element={<CashierTestPage />} />
        <Route path="/test/cashier-test2" element={<CashierTestPage2 />} />
        <Route path="/test/index" element={<TestIndexPage />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/error/:code" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>

      {/* 使用 SimpleLayout 包装的路由 */}
      <Route element={<AdminLoginLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/test/admin-login" element={<AdminLoginPage />} />
      </Route>
    </Routes>
  );
};

export default App;