import { PayOrderResult } from "../models/test/Pay";
import { PayOrderResult2 } from "../models/test/Pay";

// 模拟简单支付
export const mockSimplePayCashier = (data: any): Promise<PayOrderResult> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data) {
        resolve({
          bizOrderNo: 'P123456789',
          orderNo: 'O987654321',
          status: 'SUCCESS',
          payBody: 'Mocked Payment Data',
        });
      } else {
        reject(new Error('Payment data is invalid'));
      }
    }, 500); // 模拟延迟500ms
  });
};

// 根据业务ID获取支付状态
export const mockFindStatusByBizOrderNo = (bizOrderNo: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (bizOrderNo) {
        resolve(true); // 模拟支付成功
      } else {
        reject(new Error('BizOrderNo is invalid'));
      }
    }, 500); // 模拟延迟500ms
  });
};

// 获取手机收银台链接
export const mockGetUniCashierUrl = (): Promise<string> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('https://mocked-url.com/cashier');
    }, 500); // 模拟延迟500ms
  });
};

// 创建聚合支付码连接
export const mockCreateAggregatePayUrl = (data: any): Promise<PayOrderResult> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data) {
        resolve({
          bizOrderNo: 'P123456789',
          orderNo: 'O987654321',
          status: 'PENDING',
          payBody: 'Mocked Aggregate Payment URL',
        });
      } else {
        reject(new Error('Invalid data for creating aggregate payment URL'));
      }
    }, 500); // 模拟延迟500ms
  });
};

// 聚合条码支付
export const mockAggregateBarCodePay = (data: any): Promise<PayOrderResult> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data) {
        resolve({
          bizOrderNo: 'P123456789',
          orderNo: 'O987654321',
          status: 'SUCCESS',
          payBody: 'Mocked BarCode Payment',
        });
      } else {
        reject(new Error('Invalid data for barcode payment'));
      }
    }, 500); // 模拟延迟500ms
  });
};

export const mockCreateOrder = (data: any): Promise<PayOrderResult2> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        code: 0,
        msg: '成功',
        data: {
          payOrderId: `P${Date.now()}`, // 模拟一个订单 ID
          mchOrderNo: data.mchOrderNo,   // 使用传入的商户订单号
          orderState: 0,                 // 订单状态，假设为未支付状态
          payDataType: 'codeImgUrl',     // 模拟支付数据类型
          payData: 'https://pay.jeepay.vip/api/scan/imgs/a034b9a3cc3e89d53d936c196d5a48c219c1f01a8623733b004429d057af027e69b4220ccdc8ad29375295f30ccb3e1cf130261adeb28c64f27dc682305ec082062889a910c12dba1317fa3f69c29eb03f253f90f09d1a06b4737c0b1d59e0b898cda264c0d73af4867769029b925e1e39a61622c2fc44ee0152ab9d9b5d67ab44ed7c86e81ffe307c585b35c20eb6d977fa44c428844624c377adee71a254b9.png', // 模拟的支付二维码 URL
          errCode: null,
          errMsg: null,
        },
      });
    }, 1000); // 模拟网络延迟
  });
};
