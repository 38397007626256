import type { ColumnsType } from 'antd/es/table';
import { Book } from '../../models/test/Book';

export const tableColumnsBook: ColumnsType<Book> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '作者',
    dataIndex: 'author',
    key: 'author',
  },
  // {
  //   title: '简介',
  //   dataIndex: 'description',
  //   key: 'description',
  //   ellipsis: true, // 使内容过长时显示省略号
  // },
  // {
  //   title: '分类',
  //   dataIndex: 'category',
  //   key: 'category',
  // },
  {
    title: 'ISBN',
    dataIndex: 'isbn',
    key: 'isbn',
  },
  {
    title: '标签',
    dataIndex: 'tags',
    key: 'tags',
  },
  {
    title: '封面',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (url: string) => <img src={url} alt="书籍封面" style={{ width: '50px' }} />, // 显示缩略图
  },
  {
    title: '发行日期',
    dataIndex: 'publishedDate',
    key: 'publishedDate',
    render: (date: string) => new Date(date).toLocaleDateString(),
  },
  {
    title: '价格',
    dataIndex: 'price',
    key: 'price',
    render: (price: number) => `$${price.toFixed(2)}`,
  },
  // {
  //   title: '操作',
  //   key: 'operate',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <Button type="primary" onClick={() => handleEdit(record)}>修改</Button>
  //       <Button type="primary" danger onClick={() => handleDelete(record.id)}>删除</Button>
  //     </Space>
  //   ),
  // },
];

// // 修改按钮点击处理函数
// const handleEdit = (record: Book) => {
//   console.log('编辑书籍：', record);
//   // 实现修改逻辑，如弹出修改表单
// };

// // 删除按钮点击处理函数
// const handleDelete = (id: number) => {
//   console.log('删除书籍 ID：', id);
//   // 实现删除逻辑，如调用 API 或更新状态
// };