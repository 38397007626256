// 模拟的书籍数据
let mockBookData = [
  {
    id: 1,
    title: 'Java编程思想',
    author: 'John Doe',
    publishedDate: '2020-12-31T16:00:00.000+00:00',
    price: 29.99,
    description: 'Java编程的经典之作，适合从入门到进阶的学习。',
    category: 'Programming',
    isbn: '9781234567890',
    tags: 'Java,编程,技术',
    imageUrl: 'https://placehold.co/100X100/png',
    createdDate: '2024-09-04T03:36:36.000+00:00',
    updatedDate: '2024-09-04T03:36:36.000+00:00',
  },
  {
    id: 2,
    title: 'Spring Framework进阶',
    author: 'Jane Doe',
    publishedDate: '2022-05-14T16:00:00.000+00:00',
    price: 39.99,
    description: '深入了解Spring Framework的高级特性与实践。',
    category: 'Programming',
    isbn: '9780987654321',
    tags: 'Spring,Java,框架',
    imageUrl: 'https://placehold.co/100X100/png',
    createdDate: '2024-09-04T03:36:36.000+00:00',
    updatedDate: '2024-09-04T03:36:36.000+00:00',
  },
];

// 获取书籍列表（模拟的分页）
export const getBookMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockBookData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockBookData.length,
          size: pageSize,
          current: page,
        },
      });
    }, 500);
  });
};

// 模拟添加书籍
export const mockAddBook = (newBook: any): Promise<any> => {
  return new Promise((resolve) => {
    mockBookData.push({ ...newBook, id: mockBookData.length + 1 });
    setTimeout(() => resolve({ errorCode: 0, message: 'Book added successfully' }), 500);
  });
};

// 模拟编辑书籍
export const mockEditBook = (id: number, updatedBook: any): Promise<any> => {
  return new Promise((resolve) => {
    mockBookData = mockBookData.map((book) => (book.id === id ? { ...book, ...updatedBook } : book));
    setTimeout(() => resolve({ errorCode: 0, message: 'Book updated successfully' }), 500);
  });
};

// 模拟删除书籍
export const mockDeleteBook = (id: number): Promise<any> => {
  return new Promise((resolve) => {
    mockBookData = mockBookData.filter((book) => book.id !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'Book deleted successfully' }), 500);
  });
};

// 获取书籍详情（模拟）
export const mockFetchBookDetail = (id: number): Promise<any> => {
  const book = mockBookData.find((book) => book.id === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (book) {
        resolve({ data: book }); // 返回找到的书籍
      } else {
        reject(new Error('Book not found')); // 未找到书籍时返回错误
      }
    }, 500); // 模拟延迟500ms
  });
};