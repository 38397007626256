import React, { useState } from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsBook } from '../../components/test/tableColumnsBook'; // 从独立文件中导入表格列定义
import BookAddOrEditModal from '../../components/test/BookAddOrEditModal';
import { Book } from '../../models/test/Book';
import { fetchBookList, addBook, editBook, deleteBook } from '../../api/apiBook'; // 导入封装的 API
import { useNavigate } from 'react-router-dom';

const BookListPage: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Book[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 3,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingBook, setEditingBook] = useState<Book | null>(null); // 用于编辑的书籍

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 3) => {
    setLoading(true);
    const fetchData = fetchBookList;
    try {
      const response = await fetchData(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddBookModal = () => {
    setEditingBook(null); // 添加时不编辑任何书籍
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 提交表单，添加或编辑书籍
  const handleAddOrEditBook = async (values: any) => {
    try {
      if (editingBook) {
        // 编辑模式
        await editBook(editingBook.id, values); // 调用封装的 editBook API
        const updatedBooks = data.map((book) =>
          book.id === editingBook.id ? { ...editingBook, ...values } : book
        );
        setData(updatedBooks);
        message.success('书籍信息更新成功');
      } else {
        // 添加模式
        const newBook: Book = {
          id: data.length + 1, // 模拟自增ID
          ...values,
          publishedDate: new Date().toISOString(),
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        };
        await addBook(newBook); // 调用封装的 addBook API
        setData([...data, newBook]); // 添加新书籍到表格
        message.success('书籍添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing book:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: Book) => {
    console.log('查看书籍：', record);
    navigate(`/test/book-detail/${record.id}`); // 跳转到书籍详情页
  };

  // 编辑书籍
  const handleEdit = (record: Book) => {
    setEditingBook(record); // 设置当前编辑的书籍
    setIsModalVisible(true);
  };

  // 删除书籍
  const handleDelete = async (id: number) => {
    try {
      await deleteBook(id); // 调用封装的 deleteBook API
      const updatedData = data.filter((book) => book.id !== id);
      setData(updatedData); // 更新表格数据
      message.success('书籍删除成功');
    } catch (error) {
      console.error('Error deleting book:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsBook,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: Book) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-1xl font-bold text-center mb-4">Book Management</h2>
      <Button type="primary" onClick={showAddBookModal} className="mb-4">
        添加书籍
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <BookAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditBook}
        initialValues={editingBook}
      />
    </div>
  );
};

export default BookListPage;