import React, { useState, useEffect } from "react";
import {
  Alert,
  Card,
  Button,
  Form,
  Input,
  Radio,
  Table,
  Modal,
  message,
  Tag,
} from "antd";
import axios from "axios";
import { createOrder } from "../../api/apiCashier";
import { PayOrderResult2 } from "../../models/test/Pay";

function CashierTestPage2() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<any>("WX_NATIVE");
  const [selectedEntryPageType, setSelectedEntryPageType] = useState<any>(null);
  const [amountOption, setAmountOption] = useState<any>("0.01");
  const [amount, setAmount] = useState<any>(0.01);
  const [customAmount, setCustomAmount] = useState<any>("");
  const [orderNumber, setOrderNumber] = useState<any>(generateOrderNumber());
  const [paymentData, setPaymentData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [amountOptions, setAmountOptions] = useState<any[]>([]); // 初始金额选项为空数组
  const [form] = Form.useForm();

  useEffect(() => {
    refreshAmountOptions();
  }, []);

  interface PaymentMethod {
    code: string;
    name: string;
    icon: string;
    isH5?: boolean;
    entryPageType?: string;
    isStaticCode?: boolean;
  }

  const paymentMethods: any[] = [
    {
      category: "微信支付",
      methods: [
        {
          code: "WX_NATIVE",
          name: "微信二维码",
          icon: "https://www.jeequan.com/jee/images/payType/wx_native.svg",
        },
        {
          code: "WX_BAR",
          name: "微信条码",
          icon: "https://www.jeequan.com/jee/images/payType/wx_bar.svg",
        },
        {
          code: "WX_QR_CASHIER",
          name: "公众号/小程序",
          icon: "https://www.jeequan.com/jee/images/payType/wx_jsapi.svg",
        },
        {
          code: "WX_H5",
          name: "微信H5",
          icon: "https://www.jeequan.com/jee/images/payType/wx_h5.svg",
          isH5: true,
        },
      ],
    },
    {
      category: "支付宝支付",
      methods: [
        {
          code: "ALI_QR",
          name: "支付宝二维码",
          icon: "https://www.jeequan.com/jee/images/payType/ali_qr.svg",
        },
        {
          code: "ALI_BAR",
          name: "支付宝条码",
          icon: "https://www.jeequan.com/jee/images/payType/ali_bar.svg",
        },
        {
          code: "ALI_QR_CASHIER",
          name: "支付宝生活号",
          icon: "https://www.jeequan.com/jee/images/payType/ali_jsapi.svg",
        },
        {
          code: "ALI_PC",
          name: "支付宝PC网站",
          icon: "https://www.jeequan.com/jee/images/payType/ali_pc.svg",
        },
        {
          code: "ALI_WAP",
          name: "支付宝WAP",
          icon: "https://www.jeequan.com/jee/images/payType/ali_wap.svg",
          isH5: true,
        },
      ],
    },
    {
      category: "聚合支付",
      methods: [
        {
          code: "AUTO_BAR",
          name: "聚合被扫(商家扫用户)",
          icon: "https://www.jeequan.com/jee/images/payType/auto_bar.svg",
        },
        {
          code: "WEB_CASHIER",
          name: "web收银台",
          icon: "https://www.jeequan.com/jee/images/payType/web_cashier.svg",
        },
        {
          code: "QR_CASHIER",
          name: "聚合主扫(小程序)",
          icon: "https://www.jeequan.com/jee/images/payType/qr_cashier_wx_lite.svg",
          entryPageType: "lite",
        },
        {
          code: "QR_CASHIER",
          name: "聚合主扫(公众号)",
          icon: "https://www.jeequan.com/jee/images/payType/qr_cashier_wx_jsapi.svg",
          entryPageType: "h5",
        },
        {
          code: "QR_CASHIER",
          name: "聚合主扫(静态码)",
          icon: "https://www.jeequan.com/jee/images/payType/qr_cashier.svg",
          isStaticCode: true,
        },
      ],
    },
  ];

  useEffect(() => {
    fetchPaymentData();
  }, []);

  useEffect(() => {
    if (amountOption === "-1" && customAmount) {
      setAmount(parseFloat(customAmount));
    } else if (amountOption !== "-1") {
      setAmount(parseFloat(amountOption));
      setCustomAmount("");
    }
  }, [customAmount, amountOption]);

  useEffect(() => {
    if (orderNumber) {
      const ws = new WebSocket(
        `wss://www.jeequan.com/ws/payOrder/${orderNumber}/${new Date().getTime()}`
      );
      ws.onmessage = (event: any) => {
        const resDataJSON = JSON.parse(event.data);
        if (resDataJSON.state === "2") {
          message.success("支付成功");
          refreshOrderNumber();
          fetchPaymentData();
          ws.close();
        }
      };
      return () => {
        ws.close();
      };
    }
  }, [orderNumber]);

  function generateOrderNumber(): string {
    return (
      "M" +
      new Date().getTime() +
      Math.floor(Math.random() * (9999 - 1000) + 1000)
    );
  }

  function generateRefundNo(): string {
    return (
      "R" +
      new Date().getTime() +
      Math.floor(Math.random() * (9999 - 1000) + 1000)
    );
  }

  function generateRandomAmounts(): any[] {
    let amounts: any[] = [];
    for (let i = 0; i < 4; i++) {
      amounts.push((Math.random() * (1 - 0.1) + 0.1).toFixed(2));
    }
    return amounts;
  }

  function selectPaymentMethod(method: any) {
    setSelectedPaymentMethod(method.code);
    if (method.entryPageType) {
      setSelectedEntryPageType(method.entryPageType);
    } else if (method.isStaticCode) {
      setSelectedEntryPageType('staticCode');
    } else {
      setSelectedEntryPageType(null);
    }
  }

  function handleAmountChange(e: any) {
    const value = e.target.value;
    setAmountOption(value);
    if (value === "-1") {
      setAmount("");
    } else {
      setAmount(parseFloat(value));
      setCustomAmount("");
    }
  }

  function refreshOrderNumber() {
    setOrderNumber(generateOrderNumber());
  }

  // 更新随机金额选项
  function refreshAmountOptions() {
    const randomAmounts = generateRandomAmounts();
    setAmountOptions(["0.01", ...randomAmounts, "-1"]); // 更新金额选项
  }

  async function handlePay() {
    if (!amount || amount <= 0) {
      message.error("请输入正确的金额，0-100000之间最多两位小数");
      return;
    }

    const reqData: any = {
      amount,
      wayCode: selectedPaymentMethod,
      mchOrderNo: orderNumber,
      subject: "支付接口演示",
    };
    if (selectedEntryPageType) {
      reqData.entryPageType = selectedEntryPageType;
    }

    if (selectedPaymentMethod.endsWith("_BAR")) {
      Modal.confirm({
        title: "条码支付",
        content: (
          <div>
            <p>请输入用户条码：</p>
            <Input id="authCodeInput" />
          </div>
        ),
        onOk: () => {
          const authCodeElement: any = document.getElementById(
            "authCodeInput"
          ) as any;
          const authCode = authCodeElement ? authCodeElement.value : "";
          if (!authCode) {
            message.error("请输入用户条码");
            return Promise.reject();
          }
          reqData.authCode = authCode;
          return makePayment(reqData);
        },
      });
    } else {
      makePayment(reqData);
    }
  }

  async function makePayment(reqData: any) {
    setLoading(true);
    try {
      const res: PayOrderResult2 = await createOrder(reqData);
      if (res.code === 0) {
        const data = res.data;
        if (data.payDataType === "form") {
          const div = document.createElement("div");
          div.innerHTML = data.payData;
          document.body.appendChild(div);
          (div.querySelector("form") as any).submit();
        } else if (data.payDataType === "codeImgUrl") {
          Modal.info({
            title: "扫码支付",
            content: (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={data.payData}
                  alt="QR Code"
                  style={{
                    width: "200px",
                    height: "200px",
                    marginBottom: "10px",
                  }}
                />
                <p>支持微信、支付宝扫码</p>
              </div>
            ),
            onOk: () => {
              refreshOrderNumber();
            },
          });
        } else if (data.payDataType === "payurl") {
          window.open(data.payData, "_blank");
          refreshOrderNumber();
        } else {
          message.info(JSON.stringify(data));
          refreshOrderNumber();
        }
      } else {
        message.error(res.msg);
        refreshOrderNumber();
      }
    } catch (error) {
      message.error("接口服务异常");
      refreshOrderNumber();
    } finally {
      setLoading(false);
    }
  }

  async function fetchPaymentData() {
    try {
      const response = await axios.get("/api/pay/list");
      const res = response.data;
      if (res.code === 0) {
        setPaymentData(res.data.records || []);
      } else {
        message.error(res.msg);
      }
    } catch (error) {
      message.error("接口服务异常");
    }
  }

  function handleRefund(record: any) {
    const mchRefundNo = generateRefundNo();
    Modal.confirm({
      title: `确认退款 ${record.amount / 100} 元？`,
      onOk: async () => {
        try {
          const response = await axios.post("/api/pay/refund", {
            mchOrderNo: record.orderNo,
            mchRefundNo: mchRefundNo,
          });
          const res = response.data;
          if (res.code === 0) {
            if (res.data.state === 2) {
              message.success("退款成功");
              fetchPaymentData();
            }
          } else {
            message.error(res.msg);
          }
        } catch (error) {
          message.error("接口服务异常");
        }
      },
    });
  }

  const columns: any[] = [
    {
      title: "订单编号",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "支付金额",
      dataIndex: "amount",
      key: "amount",
      render: (text: any) => (text / 100).toFixed(2),
    },
    {
      title: "支付方式",
      dataIndex: "payType",
      key: "payType",
      render: (text: any) => {
        const payTypeMap: any = {
          WX_NATIVE: "微信二维码",
          WX_JSAPI: "公众号/小程序",
          WX_BAR: "微信条码",
          WX_H5: "微信H5",
          ALI_QR: "支付宝二维码",
          ALI_JSAPI: "支付宝生活号",
          ALI_BAR: "支付宝条码",
          ALI_PC: "支付宝PC网站",
          ALI_WAP: "支付宝WAP",
          QR_CASHIER: "聚合扫码",
          AUTO_BAR: "聚合条码",
          WEB_CASHIER: "web收银台",
        };
        return payTypeMap[text] || text;
      },
    },
    {
      title: "支付状态",
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        const statusMap: any = {
          0: <Tag color="blue">订单生成</Tag>,
          1: <Tag color="orange">待支付</Tag>,
          2: <Tag color="green">支付成功</Tag>,
          3: <Tag>支付失败</Tag>,
          4: <Tag color="cyan">已取消</Tag>,
          5: <Tag color="black">已退款</Tag>,
        };
        return statusMap[text] || text;
      },
    },
    {
      title: "客户IP",
      dataIndex: "clientIp",
      key: "clientIp",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      render: (text: any) => new Date(text).toLocaleString(),
    },
    {
      title: "操作",
      key: "action",
      render: (text: any, record: any) => (
        <Button
          type="link"
          disabled={!record.userId || record.status !== 2}
          onClick={() => handleRefund(record)}
        >
          退款
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f5f5f7" }}>
      <div className="page paydemo">
        <div className="blog-container" id="container">
          <div>
            <Alert
              message={
                <span>
                  本页面支付测试对接
                  <a
                    href="https://www.berapay.cn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    小熊支付
                  </a>
                  （使用
                  <a href="/product/berapay4plus.html">berapay plus</a>
                  搭建），下单测试需谨慎，付款失误可退回！！！
                </span>
              }
              type="info"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          </div>

          <Card style={{ marginTop: "20px" }}>
            {paymentMethods.map((category: any) => (
              <div key={category.category} style={{ marginBottom: "20px" }}>
                <h3>{category.category}</h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {category.methods.map((method: any) => (
                    <Card
                      key={method.code + (method.entryPageType || "")}
                      hoverable
                      style={{
                        width: 240, // 调整卡片宽度以适应图标和名称
                        margin: "10px",
                        border:
                          selectedPaymentMethod === method.code &&
                          ((method.entryPageType && method.entryPageType === selectedEntryPageType) ||
                          (method.isStaticCode && selectedEntryPageType === 'staticCode') ||
                          (!method.entryPageType && !method.isStaticCode))
                            ? "2px solid #1890ff"
                            : undefined,
                      }}
                      onClick={() => selectPaymentMethod(method)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={method.icon}
                          alt={method.name}
                          style={{ width: "60px", marginRight: "10px" }} // 调整图片大小和间距
                        />
                        <Card.Meta
                          title={method.name}
                          style={{ textAlign: "left" }} // 名称与图标左对齐
                        />
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            ))}
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <h3>支付信息</h3>
              <Form form={form} onFinish={handlePay}>
                <Form.Item label="订单编号">
                  <span>{orderNumber}</span>
                  <Button
                    size="small"
                    onClick={refreshOrderNumber}
                    style={{ marginLeft: "10px" }}
                  >
                    刷新订单号
                  </Button>
                </Form.Item>
                <Form.Item label="商品名称">
                  <span>支付接口演示</span>
                </Form.Item>
                <Form.Item label="支付金额(元)">
                  <Form.Item name="amountOption" noStyle>
                    <Radio.Group
                      onChange={handleAmountChange}
                      value={amountOption}
                    >
                      {amountOptions.map((option: any) => (
                        <Radio key={option} value={option}>
                          {option === "-1" ? "自定义金额" : option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  {amountOption === "-1" && (
                    <Form.Item name="customAmount" noStyle>
                      <Input
                        style={{ width: "120px", marginLeft: "10px" }}
                        placeholder="请输入金额"
                        onChange={(e) => setCustomAmount(e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <div style={{ textAlign: "right" }}>
                  <span
                    style={{
                      color: "#FD482C",
                      fontSize: "18px",
                      paddingRight: "10px",
                    }}
                  >
                    ￥{amount}
                  </span>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    立即支付
                  </Button>
                </div>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: "rgb(68, 181, 73)", fontSize: "12px" }}>
                    登录后支付，可发起退款
                  </span>
                </div>
              </Form>
            </div>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <div>
              <h3>最新支付</h3>
              <Button
                type="primary"
                onClick={fetchPaymentData}
                style={{ marginBottom: "10px" }}
              >
                刷新
              </Button>
              <Table
                dataSource={paymentData}
                columns={columns}
                rowKey="orderNo"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CashierTestPage2;