import { deleteRequest, getRequest, postRequest, putRequest } from '../utils/request';
import { getBookMockData, mockAddBook, mockEditBook, mockDeleteBook, mockFetchBookDetail } from '../mocks/mockBookData'; // 导入 Mock 函数

const USE_MOCK_DATA = false; // 切换 Mock 数据标志

// 获取书籍列表的请求
export const fetchBookList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getBookMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/book/page', {
    page,
    size: pageSize,
  });
};

// 添加新书籍的请求
export const addBook = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddBook(data);
  }
  return postRequest('/api/v1/test/book', data);
};

// 编辑书籍的请求
export const editBook = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditBook(id, data);
  }
  return putRequest(`/api/v1/test/book/${id}`, data);
};

// 删除书籍的请求
export const deleteBook = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteBook(id);
  }
  return deleteRequest(`/api/v1/test/book/${id}`);
};

// 获取书籍详情的请求
export const fetchBookDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchBookDetail(id);
  }
  return getRequest(`/api/v1/test/book/${id}`);
};