import type { ColumnsType } from 'antd/es/table';
import { Domain } from '../../models/test/Domain';

export const tableColumnsDomain: ColumnsType<Domain> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '域名',
    dataIndex: 'domainName',
    key: 'domainName',
  },
  {
    title: '注册商',
    dataIndex: 'registrar',
    key: 'registrar',
  },
  {
    title: '注册日期',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    render: (date: string) => new Date(date).toLocaleDateString(), // 将日期格式化为本地日期字符串
  },
  {
    title: '到期日期',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: (date: string) => new Date(date).toLocaleDateString(),
  },
  {
    title: '持有者',
    dataIndex: 'ownerName',
    key: 'ownerName',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '域名服务器',
    dataIndex: 'nameservers',
    key: 'nameservers',
    render: (servers: string) => servers.split(',').join(', '), // 显示以逗号分隔的域名服务器列表
  },
  {
    title: '品牌图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (url: string) => <img src={url} alt="品牌图片" style={{ width: '50px' }} />, // 显示缩略图
  },
];