import React, { useState } from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsDomain } from '../../components/test/tableColumnsDomain'; // 从独立文件中导入表格列定义
import DomainAddOrEditModal from '../../components/test/DomainAddOrEditModal';
import { Domain } from '../../models/test/Domain';
import { fetchDomainList, addDomain, editDomain, deleteDomain } from '../../api/apiDomain'; // 导入封装的 API
import { useNavigate } from 'react-router-dom';

const DomainListPage: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Domain[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 3,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingDomain, setEditingDomain] = useState<Domain | null>(null); // 用于编辑的域名

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 3) => {
    setLoading(true);
    const fetchData = fetchDomainList;
    try {
      const response = await fetchData(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddDomainModal = () => {
    setEditingDomain(null); // 添加时不编辑任何域名
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 提交表单，添加或编辑域名
  const handleAddOrEditDomain = async (values: any) => {
    try {
      if (editingDomain) {
        // 编辑模式
        await editDomain(editingDomain.id, values); // 调用封装的 editDomain API
        const updatedDomains = data.map((domain) =>
          domain.id === editingDomain.id ? { ...editingDomain, ...values } : domain
        );
        setData(updatedDomains);
        message.success('域名信息更新成功');
      } else {
        // 添加模式
        const newDomain: Domain = {
          id: data.length + 1, // 模拟自增ID
          ...values,
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        };
        await addDomain(newDomain); // 调用封装的 addDomain API
        setData([...data, newDomain]); // 添加新域名到表格
        message.success('域名添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing domain:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: Domain) => {
    console.log('查看域名：', record);
    navigate(`/test/domain-detail/${record.id}`); // 跳转到域名详情页
  };

  // 编辑域名
  const handleEdit = (record: Domain) => {
    setEditingDomain(record); // 设置当前编辑的域名
    setIsModalVisible(true);
  };

  // 删除域名
  const handleDelete = async (id: number) => {
    try {
      await deleteDomain(id); // 调用封装的 deleteDomain API
      const updatedData = data.filter((domain) => domain.id !== id);
      setData(updatedData); // 更新表格数据
      message.success('域名删除成功');
    } catch (error) {
      console.error('Error deleting domain:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsDomain,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: Domain) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-1xl font-bold text-center mb-4">Domain Management</h2>
      <Button type="primary" onClick={showAddDomainModal} className="mb-4">
        添加域名
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <DomainAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditDomain}
        initialValues={editingDomain}
      />
    </div>
  );
};

export default DomainListPage;