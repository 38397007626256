import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';

interface AddBookModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any;  // 支持传递初始值
}

const BookAddOrEditModal: React.FC<AddBookModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return (
    <Modal
      title="添加/编辑书籍"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values); // 将表单的值传给父组件的处理函数
            form.resetFields(); // 重置表单
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="addBookForm" initialValues={initialValues}>
        <Form.Item
          name="title"
          label="书籍标题"
          rules={[{ required: true, message: '请输入书籍标题' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="author"
          label="作者"
          rules={[{ required: true, message: '请输入作者' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="价格"
          rules={[{ required: true, message: '请输入价格' }]}
        >
          <InputNumber min={0} max={1000} step={0.01} />
        </Form.Item>
        <Form.Item
          name="description"
          label="书籍简介"
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          name="category"
          label="书籍分类"
          rules={[{ required: true, message: '请输入书籍分类' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="isbn"
          label="国际标准书号 (ISBN)"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tags"
          label="书籍标签"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          label="图片网址"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BookAddOrEditModal;