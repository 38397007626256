import { getRequest, postRequest } from '../utils/request'; // 实际请求工具
import {
  mockSimplePayCashier,
  mockFindStatusByBizOrderNo,
  mockGetUniCashierUrl,
  mockCreateAggregatePayUrl,
  mockAggregateBarCodePay,
  mockCreateOrder,
} from '../mocks/mockPayData'; // 模拟数据
import { PayOrderResult, PayOrderResult2 } from '../models/test/Pay';

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 发起简单支付
export const simplePayCashier = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockSimplePayCashier(data);
  }
  return postRequest('/demo/cashier/simplePayCashier', data).then((response) => response.data as PayOrderResult);
};

// 根据业务ID获取支付状态
export const findStatusByBizOrderNo = (bizOrderNo: string): Promise<boolean> => {
  if (USE_MOCK_DATA) {
    return mockFindStatusByBizOrderNo(bizOrderNo);
  }
  return getRequest('/demo/cashier/queryPayOrderSuccess', { bizOrderNo }).then((response) => response.data as boolean);
};

// 获取手机收银台链接
export const getUniCashierUrl = (): Promise<string> => {
  if (USE_MOCK_DATA) {
    return mockGetUniCashierUrl();
  }
  return getRequest('/demo/cashier/getUniCashierUrl').then((response) => response.data as string);
};

// 创建聚合支付码链接
export const createAggregatePayUrl = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockCreateAggregatePayUrl(data);
  }
  return postRequest('/demo/aggregate/createUrl', data).then((response) => response.data as PayOrderResult);
};

// 聚合条码支付
export const aggregateBarCodePay = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockAggregateBarCodePay(data);
  }
  return postRequest('/demo/aggregate/barCodePay', data).then((response) => response.data as PayOrderResult);
};

// 创建支付订单
export const createOrder = (data: any): Promise<PayOrderResult2> => {
  if (USE_MOCK_DATA) {
    return mockCreateOrder(data);
  }
  return postRequest('/test/pay/createOrder', data).then((response) => response.data as PayOrderResult2);
};